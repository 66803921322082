import React from "react";
// import {Link} from 'gatsby'
import Container from "./container";
import styled from "styled-components";

import LuwebLogo from "../images/luweb-logo-white.svg";

const StyledFooter = styled.div`
    background: #222831;
    padding: 20px 0;
    p {
        color: #d5d5d5;
        font-size: 14px;
        margin: 0;
    }
    h2 {
        font-family: KaufmannBold;
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: 48px;
    }
    h3 {
        color: #fff;
        font-size: 20px;
        margin-bottom: 2px;
    }
    address,
    a {
        font-size: 14px;
        color: #d5d5d5;
        font-style: normal;
    }
    sub span {
        font-family: KaufmannBold;
        font-size: 1.4em;
        margin: 2px;
    }
    #footerSub {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 12px;
        color: #d3d3d3;
    }
`;

const footer = () => {
    return (
        <StyledFooter>
            <Container>
                <div id="footer">
                    <h2>Alegria</h2>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0", marginBotton: "10px" }}>
                        <div style={{ paddingRight: "10px" }}>
                            <address>Antwerpsestraat 72 2640 Mortsel</address>
                            <p>Steeds telefonisch bereikbaar ook in het weekend</p>
                            <a href="tel:+3234491726">Tel. 03 449 17 26</a>
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                            <h3>Openingsuren</h3>
                            <p>
                                Ma. t/m vrij. van 9.00 tot 18.00u
                                <br />
                                Di. en woe. met avondservice
                                <br />
                                <span style={{ fontSize: "14px" }}>Steeds op afspraak</span>
                            </p>
                        </div>
                    </div>
                    {/* <hr /> */}
                    <sub id="footerSub">
                        <div id="copyright">
                            &copy; {new Date().getFullYear()} <span>Alegria</span>Alle rechten voorbehouden.
                        </div>
                        <div id="luweb">
                            Made by{" "}
                            <a href="https://www.luweb.be" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: "40px", position: "relative", top: "2px", left: "2px" }} src={LuwebLogo} alt="logo Luweb" />
                            </a>
                        </div>
                    </sub>
                </div>
            </Container>
        </StyledFooter>
    );
};

export default footer;
