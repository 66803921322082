import React, { Component } from 'react'

import Navbar from './navbar'
import SideDrawer from './SideDrawer'
import Backdrop from './Backdrop'
import Container from './container';

export class Navigation extends Component {
    state = {
        sideDrawerOpen: false
    }

    toggleSideDrawer = () => this.setState({ sideDrawerOpen: !this.state.sideDrawerOpen })

    render() {
        const { location } = this.props
        return (
            <>
                <Container>
                    <Navbar inverse={location.pathname !== '/' ? true : false} hamburgerClick={() => this.toggleSideDrawer()} />
                </Container>
                <SideDrawer open={this.state.sideDrawerOpen} handleClose={() => this.toggleSideDrawer()} />
                {this.state.sideDrawerOpen && <Backdrop clickHandler={() => this.toggleSideDrawer()} />}
            </>
        )
    }
}

export default Navigation
