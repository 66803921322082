import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import slugify from '../slugify';

const serviceLinks = (props) => {
  return (
    <ul {...props}>
      <StaticQuery
        query={
          graphql`
            query Dienstquery {
              allContentfulDienstCategorie {
                edges {
                  node {
                    naam
                    contentful_id
                  }
                }
              }
            }
          `
        }
        render={data => data.allContentfulDienstCategorie.edges
          .sort((prev, next) => prev.node.naam > next.node.naam ? 0 : -1)
          .map(service => (
            <li key={service.node.contentful_id}>
              <Link className="serviceLink" activeClassName="active" to={`/diensten/${slugify(service.node.naam)}`} >{service.node.naam}</Link>
            </li>
          ))
        }
      />
    </ul>
  )
}

export default serviceLinks
