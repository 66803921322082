import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import ServiceLinks from "./serviceLinks";

const StyledNavbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: ${props => (props.inverse ? "relative" : "absolute")};
    z-index: 20;
    left: ${props => (props.inverse ? "0" : "20px")};
    right: ${props => (props.inverse ? "0" : "20px")};
    box-sizing: border-box;
    color: ${props => (props.inverse ? "#333" : "#fff")};
    margin-bottom: 20px;
    a#logo {
        font-family: KaufmannBold;
        font-size: 50px;
        margin: 0;
        color: ${props => (props.inverse ? "#333" : "#fff")};
        text-decoration: none;
    }
    a {
        text-decoration: none;
        color: ${props => (props.inverse ? "#737373" : "#fff")};
    }
    a.active {
        color: ${props => (props.inverse ? "#222831" : "#FCC72C")};
        font-weight: bold;
    }
    .dropdown {
        position: relative;
        > ul {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.5s linear;
            position: absolute;
            left: -25%;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            background-color: #fff;
            border-radius: 5px;
            li {
                padding: 0;
                a {
                    color: #737373;
                    display: block;
                    padding: 10px 20px;
                }
                a.active {
                    background-color: #f4f4f4;
                    color: #222831;
                    font-weight: bold;
                }
                a.active:hover {
                    color: #222831;
                }
                &:hover {
                    background-color: #fcc72c;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .dropdown:hover > ul {
        visibility: visible;
        opacity: 1;
    }

    button {
        border: none;
        background: transparent;
        padding: 0;
    }
    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 24px;
        width: 30px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        box-sizing: border-box;
        &:focus {
            outline: none;
        }
        span.line {
            width: 30px;
            height: 2px;
            background: ${props => (props.inverse ? "#333" : "#fff")};
        }
    }
    ul {
        display: flex;
        list-style-type: none;
        li {
            padding: 0 20px;
            font-size: 18px;
        }
    }
    @media (max-width: 576px) {
        ul {
            display: none;
        }
    }
    @media (min-width: 577px) {
        button {
            display: none;
        }
    }
`;

const navbar = props => {
    return (
        <StyledNavbar {...props}>
            <Link to="/" id="logo">
                Alegria
            </Link>
            <ul>
                <li>
                    <Link to="/" activeClassName="active">
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/over-ons" activeClassName="active">
                        Over ons
                    </Link>
                </li>
                <li className="dropdown">
                    <span>
                        Diensten
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            style={{ position: "relative", top: "2px", left: "2px" }}
                        >
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill={props.inverse ? "#333" : "#fff"} />
                        </svg>
                    </span>
                    <ServiceLinks />
                </li>
                <li>
                    <Link to="/contact" activeClassName="active">
                        Contact
                    </Link>
                </li>
            </ul>
            <button aria-label="Menu" onClick={props.hamburgerClick}>
                <div className="hamburger">
                    <span className="line" />
                    <span className="line" />
                    <span className="line" />
                </div>
            </button>
        </StyledNavbar>
    );
};

export default navbar;
