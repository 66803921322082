import React from 'react'
// import posed, { PoseGroup } from "react-pose";

import Navigation from '../components/Navigation'
import Footer from './footer'

import 'typeface-alegreya-sans'
import '../stylesheets/layout.sass'
import SEO from './SEO';

// const Transition = posed.div({
//   enter: { opacity: 1, delay: 300, beforeChildren: true },
//   exit: { opacity: 0 },
// });

const Layout = ({ children, ...props }) => {
  return (
    <>
      <SEO />
      <Navigation {...props} />
      {/* <PoseGroup>
        <Transition key={props.location.pathname}> */}
      {children}
      {/* </Transition>
      </PoseGroup> */}
      <Footer />
    </>
  )
}

export default Layout

