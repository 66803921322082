import React from 'react'

import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  max-width: 1200px;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
`

const container = props => {
  return (
    <StyledContainer>
      {props.children}
    </StyledContainer>
  )
}

export default container
