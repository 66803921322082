import React, { Component } from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import ServiceLinks from '../components/serviceLinks';

const StyledDrawer = styled.nav`
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: ${props => props.open ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.3s ease-out;
  overflow: scroll;
  ul {
    min-height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    cursor: pointer;
    margin: 0;
    a, #toggler {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0;
      padding: 14px 30px;
      color: $darkgreen;
      text-decoration: none;
      font-size: 1.2rem;
      &:hover {
        background-color: #FCC72C;
        /* color: #fff; */
      }
    }
    a.serviceLink {
      font-size: 1em;
      margin-left: 10px;
    }
    a.active {
        border-left: solid 6px #FCC72C;
        background-color: #ececec
    }
  }
`

class SideDrawer extends Component {
  state = {
    serviceOpen: false
  }
  render () {
    return (
      <StyledDrawer {...this.props}>
        <ul>
          <li>
            <Link activeClassName="active" onClick={this.props.handleClose} to="/">Home</Link>
          </li>
          <li>
            <Link activeClassName="active" onClick={this.props.handleClose} to="/over-ons">Over ons</Link>
          </li>
          <li>
            <Link activeClassName="active" onClick={this.props.handleClose} to="/contact">Contact</Link>
          </li>
          <li onClick={() => this.setState({serviceOpen: !this.state.serviceOpen})} >
            <span id="toggler">Diensten<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg></span>
              <ServiceLinks style={{display: this.state.serviceOpen ? 'block' : 'none'}} onClick={this.props.handleClose}/>
          </li>
        </ul>
      </StyledDrawer>
    )
  }
}

export default SideDrawer
