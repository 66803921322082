import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, url, path }) => (
    <StaticQuery
        query={graphql`
            query SEO {
                site {
                    siteMetadata {
                        defaultTitle
                        titleTemplate
                        defaultDescription
                        siteUrl
                        defaultImage
                    }
                }
            }
        `}
        render={data => {
            const { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage } = data.site.siteMetadata;
            return (
                <>
                    <Helmet title={title || defaultTitle} titleTemplate={titleTemplate} htmlAttributes={{ lang: "nl-BE" }}>
                        <meta name="description" content={description || defaultDescription} />
                        <meta name="image" content={image || defaultImage} />
                        <meta name="theme-color" content="#3950D3" />
                        {url && <meta property="og:url" content={url} />}
                        {path && <link rel="canonical" href={siteUrl + path} />}
                    </Helmet>
                </>
            );
        }}
    />
);

export default SEO;
